



















































@import '@design';

.tagline {
  font-size: 3rem;
}

.green-hr {
  height: 1px;
  background: #2e7339;
}

.in-a-rush-box {
  border: 2px dashed gray;
  border-radius: 6px;
}

.cover--footer {
  margin-top: auto;
}
